@import "../_variables.scss";

.bottom-button-bar {
    bottom: 0;
    // height: 10vh;
    width: 100%;
    position: fixed;
    background: #0f0f0f;

    .button-wrapper {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

// .cancel-game-button {
//     border: 2px solid #3088c1;
//     background-color: #111;
//     color: #9aec31;
//     margin: 0 12px;
//     border-radius: 4px;
//     padding: 8px;
//     height: 45px;
//     cursor: pointer;
// }



.cancel-game-button {
    border: 2px solid #3088c1;
    margin: 30px auto;
    background-color: red;
    color: white;
    display: block;
    padding: 10px;
    border-radius: 4px;
}

.confirm-restart-overlay {
    position: absolute;
    background: $modal-color;
    height: auto;
    max-width: 800px;
    padding: 12px;
    border: 1px solid gray;
    overflow: auto;
    z-index: 99999;
    box-shadow: 0px 0px 5px 5px #4d4d4d;
    margin: auto;
    .cancel-restart-button{
        background-color: red;
        color: white;
        border-radius: 4px;

        &:hover{
            color: wheat;
            background-color: crimson;
        }

    }
}