@import '_variables.scss';

.main-content {
    margin: auto;

    .round-data-wrapper {
        min-height: 700px;

        .current-round {
            margin: 30px;
        }

        .player-wrapper {


            .mission-objective-wrapper {
                // border-bottom: 2px solid gray
                margin: 24px 0;

            }

            &.desktop-only {

                display: flex;
                justify-content: space-between;
                min-width: 1000px;

                @media screen and (min-width: 1025px) {
                    display: flex;
                }

                @media screen and (max-width: 1024px) {
                    min-width: 300px;
                    display: none;
                }
            }

            &.mobile-only {


                .swap-player-wrapper {

                    display: flex;
                    justify-content: space-between;
                    padding: 15px;

                    .wh-cl {
                        color: white;

                        &.player-one {
                            color: $main-color;
                        }

                        &.player-two {
                            color: $secondary-color;
                        }
                    }
                }

                @media screen and (min-width: 1025px) {
                    display: none;
                }

                @media screen and (min-width: 700px) and (max-width: 1024px) {
                    min-width: 700px;
                }


                @media screen and (min-width: 0) and (max-width: 700px) {
                    min-width: 300px;
                }
            }
        }
    }
}