@import "./variables";


/*scores*/
.scores-wrapper {
    .score-selector-wrapper {
        display: flex;
        justify-content: center;
        margin: 15px;

        .score-selector {
            border: 1px solid darkolivegreen;
            padding: 8px;

            &.selected {
                background: darkolivegreen;
                color: white;
            }
        }

    }
}

/*check*/
.boss-wrapper,
.check-wrapper {
    .check-selector-wrapper {
        display: flex;
        justify-content: center;
        margin: 15px;

        .ui.checkbox input:checked~.box::after,
        .ui.checkbox input:checked~label::after {
            color: #9aec31;
        }

        .checkbox-field {
            label {

                &:active,
                &:hover {

                    &:after,
                    &:before {
                        border: 1px solid #3088c1;
                        background-color: #111;
                        color: #9aec31;
                        border-radius: 2px;
                    }
                }

                &:after,
                &:before {
                    border: 1px solid #3088c1;
                    background-color: #111;
                    color: #9aec31;
                    border-radius: 2px;
                }
            }
        }

    }
}

/*manual input*/
.manual-input-wrapper {
    margin-bottom: 15px;
    .manual-input-field-wrapper {
        display: flex;
        justify-content: center;
        margin: 15px;
        margin-bottom: 0;

        input {
            border: 2px solid #3088c1;
            background-color: #111;
            color: #9aec31;
            padding: 10px;
            min-height: 17px;
            margin: 10px;
            margin-bottom: 0;
            border-radius: 4px;
            font-size: 1rem;
        }
    }
}

/*Boss*/
.boss-wrapper {
    .boss-preview {

        display: flex;
        justify-content: center;
        margin: 15px;
    }
}

/*score range*/
.score-range-wrapper {
    .score-range-selector-wrapper {
        display: flex;
        justify-content: center;
        margin: 15px;

    }
}

/* common */
.min-info {
    font-size: 10px;
    color: grey;
}