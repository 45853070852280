.main-content.home{
    margin: auto;
    .full-logo{
        margin-bottom: 100px;
    }

    .ruleset-wrapper {

        margin: 30px;


    }

    .start-wrapper{
        .start-game-button{
            max-width: 250px;
            margin:auto;
        }
    }

}