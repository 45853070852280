@import "_variables.scss";

.scores-overlay {
    position: absolute;
    background: $modal-color;
    width: 80vw;
    height: 80vh;
    max-width: 800px;
    border: 1px solid gray;
    overflow: auto;
    top: 5%;
    z-index: 99999;
    box-shadow: 0px 0px 5px 5px #4d4d4d;
}

.main-content.scores {
    margin: auto;

    .score-players-wrapper {
        display: flex;
        justify-content: space-between;
        text-align: right;
        margin: 20px;
        // min-width: 250px;
        align-items: center;

        .total-score-text {
            color: #3088c1
        }

        >div:first-child {
            text-align: left;
            margin-right: 75px;
        }

        .wh-cl {
            color: white;

            &.player-one {
                color: $main-color;
            }

            &.player-two {
                color: $secondary-color;
            }
        }

        .main-content.confirm {
            max-width: 1337px;
        }
    }

    .scores-breakdown-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 20px;

        .group-view {

            .expand-rows-wrapper {
                display: flex;
                align-items: baseline;
                justify-content: center;

                i.arrow.circle.icon {
                    margin-left: 10px;
                }
            }
        }

        .round-wrapper {
            margin: 30px;

            .mission-wrapper {
                .objective {

                    font-style: italic;
                }

                margin: 5px;
            }

            .wh-cl {
                &.one {
                    color: $main-color;
                }

                &.two {
                    color: $secondary-color;
                }
            }
        }
    }


}

.restart-wrapper {
    margin: 30px;

    .start-game-button {
        display: block;
        margin: 30px 30px 0 30px;
    }

    .cancel-game-button {
        border: 2px solid #3088c1;
        width: 80%;
        margin: 30px auto;
        background-color: red;
        color: white;
        display: block;
        padding: 10px;
    }

    .min-info {
        font-size: 10px;
        color: grey;
    }
}