.main-content {
    margin: auto;

    .top-bar {
        display: flex;
        justify-content: center;
        input {
            border: 2px solid #3088c1;
            background-color: #111;
            color: #9aec31;
            border-radius: 4px;
            height: 35px;
            text-indent: 8px;
            width: 45%;
            font-size: 1rem;
            margin: auto;
        }

        .faction-selector {

            width: 45%;
        }
    }
    
    .group-wrapper {
        &.unavailable {
            filter: grayscale(100%);
            pointer-events: none;
        }

        margin-top: 10px;

        p {
            margin: 0;

            &.one {
                color: darkolivegreen;
            }

            &.two {
                color: teal;
            }

            &.three {
                color: darkslateblue;
            }

            &.four {
                color: magenta;
            }

            &.five {
                color: red;
            }

            &.common {
                color: darkorange;
            }

        }

        &.disabled {

            .mission-block {
                filter: grayscale(100%);
            }
        }

        .mission-wrapper {
            display: grid;
            grid-gap: 1px;
            grid-template-columns: repeat(5, 1fr);
            // grid-template-columns: repeat(3, 150px);

            @media screen and (min-width: 650px) and (max-width: 1024px) {
                grid-template-columns: repeat(4, 1fr);
            }


            @media only screen and (max-width: 649px) {
                grid-template-columns: repeat(2, 1fr);
            }

            &.selected {
                .mission-block {
                    filter: grayscale(95%);
                }
            }

            .invisible {
                display: none;
            }

            .mission-block {
                padding: 10px;
                margin: 15px;
                width: 125px;
                height: 55px;
                word-wrap: break-word;
                font-size: 0.9rem;
                border-radius: 10px;
                font-weight: bold;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                &.active {
                    filter: none;
                    border: 1px solid green;
                }

                &.one {
                    background: darkolivegreen;
                }

                &.two {
                    background: teal;
                }

                &.three {
                    background: darkslateblue;
                }

                &.four {
                    background: magenta;
                }

                &.five {
                    background: red;
                }

                &.common {
                    background: darkorange;
                }

                &.selected {
                    background: green;
                    transform: scale(125%);
                }
            }

        }
    }
}