@import "./_variables.scss";
@import "../styles/_reactToggle.scss";

.App {
  min-width: 100vw;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  font-family: Verdana, open sans, Arial, helvetica neue, Helvetica, sans-serif;
  background: #151515;


  //* LAYOUT *//
  .main-content {
    max-width: 1337px;
    // margin-top: 30px;

    @media screen and (max-width: 500px) {
      margin-top: 50px;
    }

    overflow-y: auto;
    padding-right: 10px;
    // max-height: 90vh;
  }
}

.disclaimer-top-bar {
  position: fixed;
  background: #0f0f0f;
  bottom: 0;
  width: 100%;
  height: 5vh;
  z-index: 100;
}

.scroll-to-top {
  z-index: 1000;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  animation: fadeIn 400ms ease-in-out 1s both;
  cursor: pointer;

  .arrow {
    i.arrow::before {
      color: #00c0ff;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.names {
  display: flex;
  justify-content: space-between;
}

.mission-dropdown {
  width: 150px;
  display: block;
}

.input-one {
  display: flex;
  justify-content: center;
}

.main-button {
  border: 2px solid #3088c1;
  background-color: #111;
  color: #9aec31;
  margin: 0 12px;
  border-radius: 4px;
  padding: 8px;
  height: 45px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.45;
  }

  &:hover {
    color: darkolivegreen
  }
}

.next-round-button {
  margin-top: 5px;
}

.ui.dropdown.selection.main-dropdown {
  border: 2px solid #3088c1;
  background-color: #111;
  color: #9aec31;
  padding: 10px;
  min-height: 17px;
  margin: 10px;
  font-size: 1rem;

  &:hover {
    border-color: #3088c1
  }

  .divider.text {
    color: #9aec31 !important;
  }

  i.dropdown.icon {
    padding: 10px;
  }

  .item {

    &:hover {
      .text {
        color: #9aec31;
      }
    }

    .text {
      color: white;
    }
  }

  .visible.menu {
    border: 2px solid #3088c1;
    background-color: #111;
    color: #9aec31;
    max-height: 15rem;

    .item {

      border-color: #3088c1
    }
  }
}


input {
  border: 2px solid #3088c1;
  background-color: #111;
  color: #9aec31;
  padding: 10px;
  min-height: 17px;
  margin: 10px;
  margin-bottom: 0;
  border-radius: 4px;
  font-size: 1rem;
}