/* Main */
$bg-color: #111;
$highlight-color: #dbd107;
$border-color: #7d7d7d;
$blizz-color: #00c0ff;
$modal-color: #0f0f0f;


$main-color:#9aec31;
$main-color-darker:#007B0C;
$secondary-color: #4d4295;
$secondary-color-darker: #2c2075;

$necrolord-color: #163e35;


/* max sizes */
$mobile-size: 768px;
$tablet-size: 1200px;
$mobile-sm-size: 400px;