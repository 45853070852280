.timers-bar {
    display: flex;
    justify-content: space-between;
    margin: 30px;

    .timer {
        color: red;
        &.running {

            color: #9aec31;
        }
    }
}