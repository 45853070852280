@import "_variables.scss";

.confirm-players-wrapper {
    display: flex;
    justify-content: space-between;
    text-align: right;
    margin: 20px;
    min-width: 250px;
    border-bottom: 2px solid gray;
    padding: 12px 0;

    >div:first-child {
        text-align: left;
        margin-right: 75px;
    }

    .wh-cl {
        color: white;

        &.one {
            color: $main-color;
        }

        &.two {
            color: $secondary-color;
        }
    }

    .main-content.confirm {
        max-width: 1337px;
    }
}


.clock-wrapper{

    .manual-time-input{
        margin-top: 5px;
        // display: flex;
        // justify-content: center;
        .manual-input-field{
            margin-bottom: 10px;
            padding: 5px;
        }
    }
}

.toggle-wrapper {
    margin: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.error-data {
    .cancel-game-button {
        border: 2px solid #3088c1;
        width: 80%;
        margin: 30px auto;
        background-color: red;
        color: white;
        display: block;
        padding: 10px;
    }
}

.start-wrapper {
    margin: 30px;

    .start-game-button {
        display: block;
        margin: 30px 30px 0 30px;
    }


    .min-info {
        font-size: 10px;
        color: grey;
    }
}