
.main-content.endgame{
    .start-wrapper {
        .item.start-game-button {
            max-width: 250px;
            margin: auto;
        }
    }
    .confirm-players-wrapper{
        border:none;
        .mission-objective-wrapper{
            // border-bottom: 2px solid gray;
            margin-bottom: 24px;
    
        }
    }
    
}